import { ethers } from "ethers";
import { getWeb3 } from "./connectors";
import tokenAbi from '../json/token.json';
import lpAbi from '../json/lpabi.json';
import nftAbi from '../json/nft.json';
import daoAbi from '../json/daoAbi.json';
import { contract } from "./constant";
import { DEFAULT_CHAIN, ALCHEMY_BASE_URL , ALCHEMY_BASE_URL_WALLET } from "./constant";
import axios from "axios";
import { MulticallContractWeb3 } from "./useContracts";




export const getContract = (abi, address, library) => {
  try {
    return new ethers.Contract(address, abi, library.getSigner())
  }
  catch {
    return false;
  }
}




export const formatPrice = (num) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  return new Intl.NumberFormat('ja-JP').format(parseFloat(num).toFixed(18));
}


export const getWeb3Contract = (abi, address) => {
  let web3 = getWeb3();
  return new web3.eth.Contract(abi, address);
}


export const getTokenContract = () => {
  let web3 = getWeb3();
  return new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].CARBON_ADDRESS);

}

export const getLpContract = (addrsess) => {
  let web3 = getWeb3();
  return new web3.eth.Contract(lpAbi, addrsess);
}

export const getNFTContract = () => {
  let web3 = getWeb3();
  return new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);
}

export const getDaoContract = () =>{
  let web3 = getWeb3();
  return new web3.eth.Contract(daoAbi, contract[DEFAULT_CHAIN].DAO_ADDRESS);
}


export const getAllNFTDetails = async () => {

  let userNftData = [];
  async function* fetchData() {
    try {
      const fetchURL = `${ALCHEMY_BASE_URL}?contractAddress=${contract[DEFAULT_CHAIN].NFT_ADDRESS}`
      const config = {
        method: 'get',
        url: fetchURL,

      };
      let data = await axios(config);
      if (data && data.data && data.data.ownerAddresses) {
        yield { 'error': 'OK', data: data.data.ownerAddresses };

      }
      else {
        yield { 'error': 'OK', data: [] };
      }
    } catch (err) {
      yield { 'error': err.message };
    }

  }

  for await (const nftData of fetchData()) {
    userNftData = nftData;
  }
  
  return userNftData;
}


export const getHolderVotingPower = async (hoderlist = []) => {
  let userVotingPower = [];
  let daoContract = getDaoContract();
  let error = 'OK';
  let count = 1;

  const holderChunk = hoderlist.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 100)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, []);

  
  async function* fetchData() {
    let i = 0;
    while (i < holderChunk.length) {
      let callArray = [];

      callArray = holderChunk[i].map((value) => {
        return daoContract.methods.getVotingWeight(value);
      });

      try {
        let nftData = await MulticallContractWeb3(callArray);
        let addressWithPower = [];
        
        // eslint-disable-next-line
        await holderChunk[i].map((rowdata, index) => {
          addressWithPower[index] = { id : count , address : rowdata, power : nftData[index]};
          count++;
          return true;
        })
        yield {error : 'OK' , data : addressWithPower };

      } catch (err) {
        yield { 'error': err.message };
        break;
      }
      i++;
    }

  }

  for await (const nftData of fetchData()) {
    if(nftData.error === 'OK' &&  nftData.data ){
      userVotingPower = userVotingPower.concat(nftData.data);
    }
    else{
      userVotingPower = [];
      error = nftData.error;
    }


  }

  return {userVotingPower , error };
}

export const getWalletNFTDetails = async (walletAddress = '') => {
  if (walletAddress) {
    let userNftData = [];
    let pageKey = '';
    let error = 'OK';

    async function* fetchData() {
      try {
        while (true) {
          const fetchURL = !contract[DEFAULT_CHAIN].NFT_ADDRESS
            ? `${ALCHEMY_BASE_URL_WALLET}?owner=${walletAddress}&withMetadata=true`
            : pageKey ? `${ALCHEMY_BASE_URL_WALLET}?owner=${walletAddress}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&pageKey=${pageKey}&withMetadata=true` : `${ALCHEMY_BASE_URL_WALLET}?owner=${walletAddress}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&withMetadata=true`;


          const config = {
            method: 'get',
            url: fetchURL,

          };
          let data = await axios(config);
          if (data.data && data.data !== undefined && data.data.ownedNfts.length > 0) {
            yield {error : 'OK' , data : data.data };
          }
          if (data.data.pageKey && data.data.pageKey !== '') {
            pageKey = data.data.pageKey;
          }
          else {
            break;
          }

        }
      } catch(err) {
        yield {error : err.message };
      }

    }

    for await (const nftData of fetchData()) {
      if(nftData.error === 'OK' &&  nftData.data ){
        userNftData = userNftData.concat(nftData.data.ownedNfts);
          
        
      }
      else{
        console.log('111111111')
        userNftData = [];
        error = nftData.error;
      }
        
    }

    return { error , userNftData};
  }
  else {
    return false;
  }

}
