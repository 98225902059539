import React from 'react';
import { useState } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { getContract } from '../hooks/contractHelper';
import daoAbi from '../json/daoAbi.json';
import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import { getWeb3 } from '../hooks/connectors';

export default function AdminSetup() {
    const { account, chainId, library } = useWeb3React();
    const [nftAddress, setNftAddress] = useState('');
    const [nfttokenAddress, setNfttokenAddress] = useState('');
    const [tokenId, setTokenId] = useState('');
    const [tokenAddress, setTokenAddress] = useState('');
    const [enableVote, setEnableVote] = useState('');
    const [inputFields, setInputFields] = useState([{
        service: 'Yes',
    }]);

    const addInputField = () => {

        setInputFields([...inputFields, {
            service: '',
        }])

    }

    const removeInputFields = (index, e) => {
        e.preventDefault();
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }


    const handleAdminSubmit = async (id) => {
        try {
            if (account) {
                if (chainId) {
                    let daoContract = getContract(daoAbi, contract[DEFAULT_CHAIN].DAO_ADDRESS, library);
                    let tx;
                    if (id === 1) {
                        if (enableVote === '') {
                            toast.error('Please enter valid input value!!');
                            return;
                        }
                        tx = await daoContract.disableVoting(enableVote, { 'from': account });
                    }
                    else if (id === 2) {
                        const rows = [...inputFields];
                        let voteOption = [];
                        rows.map((product) => {
                            if (product.service !== '') {
                                voteOption.push(product.service);
                            }
                            return true;
                        })
                        if (voteOption.length <= 0) {
                            toast.error('Please enter valid input value!!');
                            return;
                        }
                        tx = await daoContract.setVotingWeight(voteOption, { 'from': account });
                    }
                    else if (id === 5) {
                        if (nftAddress === '') {
                            toast.error('Please enter valid input value!!');
                            return;
                        }
                        tx = await daoContract.setNft(nftAddress, { 'from': account });
                    }
                    else if (id === 6) {
                        if (nfttokenAddress === '' || tokenId === '') {
                            toast.error('Please enter valid input value!!');
                            return;
                        }
                        tx = await daoContract.rescueNft1155(nfttokenAddress, tokenId, { 'from': account });
                    }
                    else if (id === 7) {
                        if (tokenAddress === '') {
                            toast.error('Please enter valid input value!!');
                            return;
                        }
                        tx = await daoContract.rescueToken(tokenAddress, { 'from': account });
                    }
                    else if (id === 8) {
                        tx = await daoContract.rescueFunds({ 'from': account });
                    }
                    else {
                        toast.error('something went wrong!!');
                        return;
                    }

                    toast.loading('Waiting for confirmation..');
                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                            }
                        }
                    }, 5000);

                }
                else {
                    toast.error('Please connect mainnet chain!!');
                }
            }
            else {
                toast.error('Please connect wallet!!');
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
        }
    }



    return (
        <div className="page-wrapper mt-4">
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Admin Setup</h4>
                                            <h6 className="card-subtitle">change contract settings from here</h6>
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Value</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Disable Voting</td>
                                                            <td>
                                                                <input type="text" className="form-control" value={enableVote} onChange={(e) => setEnableVote(e.target.value)} placeholder="Disable or Enable Voting ex.true or false" />
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => handleAdminSubmit(1)} className="btn btn-warning text-white">
                                                                    <span className="btn-text">Submit</span>
                                                                </button>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td>set Vote Weight</td>
                                                            <td>
                                                                {inputFields.map((data, index) => {
                                                                    const { service } = data;
                                                                    return (
                                                                        <>
                                                                            <div className="input-group mb-3">
                                                                                <input type="number" onChange={(evnt) => handleChange(index, evnt)} value={service} name="service" className="form-control mb-1" placeholder="Enter value Vote Weight.. ex.100" />
                                                                                {(index === 0) ? (
                                                                                    <div className="input-group-append text-end">
                                                                                        <button className="btn btn-success text-white mx-1" type="button" onClick={(e) => addInputField(e)}>
                                                                                            <i className="fa fa-plus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="input-group-append">
                                                                                        <button className="btn btn-success text-white mx-1" type="button" onClick={(e) => removeInputFields(index, e)}>
                                                                                            <i className="fa fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => handleAdminSubmit(2)} className="btn btn-warning text-white">
                                                                    <span className="btn-text">Submit</span>
                                                                </button>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td>set NFT Address</td>
                                                            <td>
                                                                <input type="text" value={nftAddress} onChange={(e) => setNftAddress(e.target.value)} className="form-control" placeholder="Enter NFT Address" />
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => handleAdminSubmit(5)} className="btn btn-warning text-white">
                                                                    <span className="btn-text">Submit</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rescue NFT1155</td>
                                                            <td>
                                                                <input type="text" value={nfttokenAddress} onChange={(e) => setNfttokenAddress(e.target.value)} className="form-control" placeholder="token Address" />
                                                                <input type="number" value={tokenId} onChange={(e) => setTokenId(e.target.value)} min="0" className="form-control mt-1" placeholder="token id" />
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => handleAdminSubmit(6)} className="btn btn-warning text-white">
                                                                    <span className="btn-text">Submit</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rescue Token</td>
                                                            <td>
                                                                <input type="text" value={tokenAddress} onChange={(e) => setTokenAddress(e.target.value)} className="form-control" placeholder="token Address" />
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => handleAdminSubmit(7)} className="btn btn-warning text-white">
                                                                    <span className="btn-text">Submit</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rescue Funds</td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => handleAdminSubmit(8)} className="btn btn-warning text-white">
                                                                    <span className="btn-text">Submit</span>
                                                                </button>
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
