import { useEffect, useState , useContext } from "react"
import { MulticallContractWeb3 } from "../hooks/useContracts";
import { toast } from "react-toastify";
import { getDaoContract, getNFTContract } from "./contractHelper";
import { supportNetwork } from "./network";
import { IPFS_URL, DEFAULT_CHAIN } from "./constant";
import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Context } from "../context/context";

export const useProposalStats = (updater) => {
    let {setProgress} = useContext(Context);
    let { page, pageSize, oldData } = updater;
    const provider = new ethers.providers.JsonRpcProvider(supportNetwork[DEFAULT_CHAIN].rpc);
    const [stats, setStats] = useState({
        proposals: [],
        lastId: 0,
        loading: true
    });

    const daoContract = getDaoContract();

    useEffect(() => {
        const fetch = async () => {
            setProgress(40)
            try {

                const data = await MulticallContractWeb3([
                    daoContract.methods.proposalId(), //0
                ])
                let proposals = [];
                if (data[0] > 0) {
                    let totalPages = Math.ceil(data[0] / pageSize);
                    let start = totalPages - page > 0 ? (totalPages - page) * pageSize : 1;
                    let end = start + pageSize <= data[0] ? start + pageSize - 1 : data[0];
                    if (end <= 0) {
                        end = 1;
                    }

                    let callArray = [];
                    let z = 0;
                    for (let i = end; i >= start; i--) {
                        callArray[z] = daoContract.methods.proposals(i);
                        z++;
                    }
                    proposals = await MulticallContractWeb3(callArray);

                }

                setProgress(60)

                Promise.all(proposals.map(async (rowdata) => {
                    let ensName = await provider.lookupAddress(rowdata[1]);
                    let ipfsText = await axios.get(`${IPFS_URL}${rowdata[2]}`);
                    let proposalTitle = '';
                    if (ipfsText.status === 200 && ipfsText.data && ipfsText.data !== '') {
                        proposalTitle = ipfsText.data;
                    }


                    return { ...rowdata, ensName: ensName === null ? rowdata[1] : ensName, proposalTitle };
                })).then((response) => {
                    setProgress(100);
                    setStats({
                        proposals: oldData.concat(response),
                        lastId: data[0],
                        loading: false,

                    })
                })





            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason);
                setStats({
                    proposals: [],
                    lastId: 0,
                    loading: false
                })
                setProgress(100);


            }

        }

        if (daoContract) {
            fetch();
        }
        // eslint-disable-next-line
    }, [updater]);

    return stats;
}

export const useProposalIdStats = (id, updater) => {
    let {setProgress} = useContext(Context);
    const { account } = useWeb3React();
    const provider = new ethers.providers.JsonRpcProvider(supportNetwork[DEFAULT_CHAIN].rpc);
    const [stats, setStats] = useState({
        id: id,
        author: '',
        proposalText: '',
        createAt: 0,
        totalOption: 0,
        status: '',
        loading: true,
        getProposalUserVote: false,
        getProposalVotes: 0,
        totalPower: 0
    });

    const daoContract = getDaoContract();
    const nftContract = getNFTContract();

    useEffect(() => {
        const fetch = async () => {
            setProgress(40);
            if (id) {
                try {

                    const data = await MulticallContractWeb3(
                        account ? [
                            daoContract.methods.proposals(id), //0
                            daoContract.methods.getProposalOptions(id), //1
                            daoContract.methods.getProposalVotes(id), //2
                            daoContract.methods.getVotingWeightLength(),  //3
                            daoContract.methods.getPower(),  //4
                            daoContract.methods.getProposalUserVote(id, account) //5
                        ] :
                            [
                                daoContract.methods.proposals(id), //0
                                daoContract.methods.getProposalOptions(id), //1
                                daoContract.methods.getProposalVotes(id), //2
                                daoContract.methods.getVotingWeightLength(),  //3
                                daoContract.methods.getPower()  //4
                            ]
                    )
                    setProgress(50);

                    let callArr = [];
                    for (let i = 0; i < data[3]; i++) {
                        callArr[i] = nftContract.methods.totalSupply(i);

                    }

                    const testData = await MulticallContractWeb3(callArr);
                    setProgress(70);
                    let totalPower = 0;

                    for (let i = 0; i < data[3]; i++) {
                        totalPower += parseFloat(testData[i]) * parseFloat(data[4][i]);
                    }

                    let ensName = await provider.lookupAddress(data[0][1]);
                    let ipfsText = await axios.get(`${IPFS_URL}${data[0][2]}`);
                    let proposalTitle = '';
                    if (ipfsText.status === 200 && ipfsText.data && ipfsText.data !== '') {
                        proposalTitle = ipfsText.data;
                    }

                    setStats({
                        id: id,
                        author: ensName === null ? data[0][1] : ensName,
                        proposalText: proposalTitle,
                        createAt: data[0][3],
                        totalOption: data[1],
                        status: data[0][4],
                        loading: false,
                        getProposalUserVote: data[5] ? data[5] : false,
                        getProposalVotes: data[2],
                        totalPower: totalPower
                    })

                    setProgress(100);



                }
                catch (err) {
                    console.log(err.message);
                    toast.error(err.reason);
                    setProgress(100);

                }
            }

        }

        fetch();

        // eslint-disable-next-line
    }, [updater, id, account]);

    return stats;
}


export const useProposalDashboardStats = () => {
    let {setProgress} = useContext(Context);
    const provider = new ethers.providers.JsonRpcProvider(supportNetwork[DEFAULT_CHAIN].rpc);
    const [stats, setStats] = useState({
        proposals: [],
        lastId: 0,
        loading: true
    });

    const daoContract = getDaoContract();

    useEffect(() => {
        const fetch = async () => {
            setProgress(60);
            try {

                const data = await MulticallContractWeb3([
                    daoContract.methods.proposalId(), //0
                ])
                let proposals = [];
                if (data[0] > 0) {
                    let start = data[0] > 2 ? data[0] - 1 : 1;
                    let end = start + 1 > data[0] ? data[0] : start + 1;

                    let callArray = [];
                    let z = 0;

                    for (let i = start; i <= end; i++) {
                        callArray[z] = daoContract.methods.proposals(i);
                        z++;
                    }

                    proposals = await MulticallContractWeb3(callArray);


                }



                Promise.all(proposals.map(async (rowdata) => {
                    let ensName = await provider.lookupAddress(rowdata[1]);
                    let ipfsText = await axios.get(`${IPFS_URL}${rowdata[2]}`);
                    let proposalTitle = '';
                    if (ipfsText.status === 200 && ipfsText.data && ipfsText.data !== '') {
                        proposalTitle = ipfsText.data;
                    }


                    return { ...rowdata, ensName: ensName === null ? rowdata[1] : ensName, proposalTitle };
                })).then((response) => {
                    setProgress(100);
                    console.log(response)
                    setStats({
                        proposals: response,
                        lastId: data[0],
                        loading: false,

                    })
                })





            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason);
                setStats({
                    proposals: [],
                    lastId: 0,
                    loading: false
                })
                setProgress(100);


            }

        }

        if (daoContract) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    return stats;
}





