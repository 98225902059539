import React from 'react';
import DataTable from 'react-data-table-component';
import { useVotersStats } from '../hooks/useVoterState';


export default function VotersInfo() {
    const voterStats = useVotersStats();

    const columns = [
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
            minWidth : '80%'
        },
        {
            name: 'Power',
            selector: row => row.power,
            sortable: true,
            minWidth : '20%'
        },
    ];

    return (
        <div className='row'>
            <div className='col-12 col-md-8'>
                <div className='card'>
                    <div className="card-body">
                        <h4 className="card-title">Votes({voterStats.voterList ? voterStats.voterList.length : 0})</h4>
                        {/* <div className="table-responsive"> */}
                            <DataTable
                                columns={columns}
                                data={voterStats.voterList ? voterStats.voterList : []}
                                pagination
                                progressPending={voterStats.loading}
                            />
                        {/* </div> */}
                    </div>

                </div>
            </div>
        </div>
    )
}
