import React, { useMemo } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import queryString from "query-string";
import { useAccountStats } from '../hooks/useAccount';



export default function Sidebar() {
    const router = useRouter();
    const accStats = useAccountStats(1);

    return (
        <aside className="left-sidebar">
            <div className="scroll-sidebar">
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        <li>
                            <Link className={`waves-effect waves-dark ${router.pathname === "/" ? "active" : ""}`} to="/" aria-expanded="false">
                                <i className="icon-speedometer"></i>
                                <span className="hide-menu">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link className={`waves-effect waves-dark ${router.pathname === "/profile" ? "active" : ""}`} to="/profile" aria-expanded="false">
                                <i className="icon-screen-desktop"></i>
                                <span className="hide-menu">Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link className={`waves-effect waves-dark ${router.pathname === "/dao-details" || router.pathname === "/proposal-info" ? "active" : ""}`} to="/dao-details" aria-expanded="false">
                                <i className="icon-energy"></i>
                                <span className="hide-menu">DAO</span>
                            </Link>
                        </li>
                        {accStats.isAdmin &&
                            <>
                                <li>
                                    <Link className={`waves-effect waves-dark ${router.pathname === "/authorized" ? "active" : ""}`} to="/authorized" aria-expanded="false">
                                        <i className="icon-drawar"></i>
                                        <span className="hide-menu">Create Proposal</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`waves-effect waves-dark ${router.pathname === "/evaluate-proposal" ? "active" : ""}`} to="/evaluate-proposal" aria-expanded="false">
                                        <i className="icon-check"></i>
                                        <span className="hide-menu">Evaluate Proposal</span>
                                    </Link>
                                </li>



                                <li>
                                    <Link className={`waves-effect waves-dark ${router.pathname === "/admin" ? "active" : ""}`} to="/admin" aria-expanded="false">
                                        <i className="icon-chemistry"></i>
                                        <span className="hide-menu">Admin Panel</span>
                                    </Link>
                                </li>
                            </>
                        }
                        {/* <li>
                            <a className="waves-effect waves-dark" href="#sec" aria-expanded="false">
                                <i className="icon-book-open"></i>
                                <span className="hide-menu">Docs</span>
                            </a>
                        </li> */}
                        <li>
                            <a className="waves-effect waves-dark" href="#sec" aria-expanded="false">
                                <i className="icon-globe"></i>
                                <span className="hide-menu">Website</span>
                            </a>
                        </li>
                        {/* <li>
                            <a className="waves-effect waves-dark" href="#sec" aria-expanded="false">
                                <i className="mdi mdi-telegram"></i>
                                <span className="hide-menu">Telegram</span>
                            </a>
                        </li> */}
                        {/* <li>
                            <a className="waves-effect waves-dark" href="#sec" aria-expanded="false">
                                <i className="mdi mdi-twitter"></i>
                                <span className="hide-menu">Twitter</span>
                            </a>
                        </li> */}

                    </ul>
                </nav>

            </div>

        </aside>
    )
}


export function useRouter() {
    const params = useParams();
    const location = useLocation();

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: location.push,
            replace: location.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            location,

        };
    }, [params, location]);
}
