import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getContract } from '../hooks/contractHelper';
import daoAbi from '../json/daoAbi.json';
import { toast } from 'react-toastify';
import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import { getWeb3 } from '../hooks/connectors';

export default function EvaluateProposal() {
    const { account, chainId, library } = useWeb3React();
    const [loading, setLoading] = useState(false);
    const [aloading, setALoading] = useState(false);
    const [evaluateId, setEvaluateId] = useState('');
    const [cancelId, setCancelId] = useState('');

    const handleEvaluateSubmit = async () => {
        setALoading(true);
        try {
            if (account) {
                if (chainId) {
                    if(evaluateId !== ''){
                        let daoContract = getContract(daoAbi, contract[DEFAULT_CHAIN].DAO_ADDRESS, library);
                        let tx = await daoContract.evaluateProposal(evaluateId, { 'from': account });
                        
                        toast.loading('Waiting for confirmation..');
                        var interval = setInterval(async function () {
                            let web3 = getWeb3();
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('success ! your last transaction is success');
                                    setALoading(false);
                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('error ! Your last transaction is failed.');
                                    setALoading(false);
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('error ! something went wrong.');
                                    setALoading(false);
                                }
                            }
                        }, 5000);
                    }
                    else{
                        toast.dismiss();
                        toast.error('please enter valid Evaluate ID!!');
                        setALoading(false);
                    }

                }
                else {
                    toast.dismiss();
                    toast.error('Please connect mainnet chain!!');
                    setALoading(false);
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect wallet!!');
                setALoading(false);
            }
        }
        catch (err) {
            toast.dismiss();
            toast.error(err.reason ? err.reason : err.message);
            setALoading(false);
        }
    }

    const handleCancelSubmit = async () => {
        setLoading(true);
        try {
            if (account) {
                if (chainId) {
                    if(cancelId !== ''){
                        let daoContract = getContract(daoAbi, contract[DEFAULT_CHAIN].DAO_ADDRESS, library);
                        let tx = await daoContract.evaluateProposal(cancelId, { 'from': account });
                        
                        toast.loading('Waiting for confirmation..');
                        var interval = setInterval(async function () {
                            let web3 = getWeb3();
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('success ! your last transaction is success');
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);
                    }
                    else{
                        toast.dismiss();
                        toast.error('please enter valid Cancel ID!!');
                        setLoading(false);
                    }

                }
                else {
                    toast.dismiss();
                    toast.error('Please connect mainnet chain!!');
                    setLoading(false);
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect wallet!!');
                setLoading(false);
            }
        }
        catch (err) {
            toast.dismiss();
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }



    return (
        <div className="page-wrapper mt-4">
            <div className='container-fluid'>
                <div className="row">
                    {account ? (
                        <>
                            <div className="col-12 col-md-6">

                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Evaluate Proposal</h4>
                                                    <form className="form-horizontal mt-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Proposal ID</label>
                                                            <input type="number" className="form-control" value={evaluateId} onChange={(e) => setEvaluateId(e.target.value)} />
                                                        </div>
                                                        <div className="form-actions">
                                                            <div className="card-body text-center">
                                                                <button type="button" disabled={aloading} className="btn btn-success text-white" onClick={()=>handleEvaluateSubmit()}>
                                                                    {aloading ? 'loading...' : 'Submit'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">

                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Cancel Proposal</h4>
                                                    <form className="form-horizontal mt-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Proposal ID</label>
                                                            <input type="number" className="form-control" value={cancelId} onChange={(e) => setCancelId(e.target.value)} />
                                                        </div>
                                                        <div className="form-actions">
                                                            <div className="card-body text-center">
                                                                <button type="button" disabled={loading} className="btn btn-success text-white" onClick={()=>handleCancelSubmit()}>
                                                                    {loading ? 'loading...' : 'Submit'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-12 mt-5 mb-5">
                                        <div className="text-center">
                                            <h1 className="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
                                            <hr className="w3-border-white w3-animate-left" style={{ "margin": "auto", "width": "50%" }} />
                                            <h3 className="w3-center w3-animate-right">You dont have permission to view this page.</h3>
                                            <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
                                            <h6 className="w3-center w3-animate-zoom">error code:403 forbidden</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}
