import React from 'react';
import UserNftSection from '../components/UserNftSection';
import { formatPrice } from '../hooks/contractHelper';
import { useAccountStats } from '../hooks/useAccount';


export default function Profile() {
  const accStats = useAccountStats(1);
  

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row g-0 mt-5 ">
          <div className="col-lg-4 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-grid"></i></h3>
                        <p className="text-white">YOUR BALANCE</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    {accStats.token_balance ? formatPrice(accStats.token_balance) : 0} C12
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-briefcase"></i></h3>
                        <p className="text-white">HOLDING WORTH</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    ${accStats.holdingWorth ? formatPrice(accStats.holdingWorth) : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-chart"></i></h3>
                        <p className="text-white">Your NFT Balance</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    {accStats.nft_balance ? formatPrice(accStats.nft_balance) : 0} C12DAO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserNftSection/>
      </div>
    </div>
  )
}
