import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import PreLoader from "./components/PreLoader";
import Sidebar from "./components/Sidebar";
import { Context } from "./context/context";
import AdminSetup from "./pages/AdminSetup";
import Authorized from "./pages/Authorized";
import DaoDetails from "./pages/DaoDetails";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import ProposalInfo from "./pages/ProposalInfo";
import LoadingBar from 'react-top-loading-bar'
import EvaluateProposal from "./pages/EvaluateProposal";



function App() {

  let {progress , setProgress} = useContext(Context)

  return (
    <div className="App">
      <Router>
        <LoadingBar
          color='#e9a41c'
          progress={progress}
          height={5}
          onLoaderFinished={() => setProgress(0)}
        />
        <ToastContainer pauseOnFocusLoss={false} />
        <PreLoader />
        <div id="main-wrapper">
          <Header />
          <Sidebar />
          <Switch>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route exact path="/dao-details">
              <DaoDetails />
            </Route>
            <Route exact path="/proposal-info/:id">
              <ProposalInfo />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/admin">
              <AdminSetup />
            </Route>
            <Route exact path="/authorized">
              <Authorized />
            </Route>
            <Route exact path="/evaluate-proposal">
              <EvaluateProposal />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
