import { useEffect, useState, useContext } from "react"
import { contract, DEFAULT_CHAIN } from "./constant";
import { MulticallContractWeb3 } from "../hooks/useContracts";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { getNFTContract, getTokenContract, getLpContract, getDaoContract, getWalletNFTDetails } from "./contractHelper";
import { Context } from "../context/context";


export const useAccountStats = (updater) => {
    let { setProgress } = useContext(Context);
    const { account, chainId } = useWeb3React();

    const [stats, setStats] = useState({
        eth_price: 0,
        carbon_price: 0,
        token_balance: 0,
        user_nft: [],
        nft_balance: 0,
        holdingWorth: 0,
        isAdmin: false,
        votingWeights: 0
    });


    const tokenContract = getTokenContract();
    const carbonLpContract = getLpContract(contract[DEFAULT_CHAIN].CARBON_LP_ADDRESS);
    const ethLpContract = getLpContract(contract[DEFAULT_CHAIN].WETH_USD_LP_ADDRESS);
    const nftContract = getNFTContract();
    const daoContract = getDaoContract();


    useEffect(() => {
        const fetch = async () => {
            setProgress(40)
            try {

                const data = await MulticallContractWeb3([
                    carbonLpContract.methods.getReserves(), //0
                    ethLpContract.methods.getReserves(), //1
                    tokenContract.methods.decimals(), //2
                    tokenContract.methods.balanceOf(account), //3
                ]);

                const nftData = await MulticallContractWeb3(
                    [
                        daoContract.methods.getVotingWeightLength(), //0
                        daoContract.methods.getVotingWeight(account), //1
                        daoContract.methods.getPower(), //2
                    ]
                )

                setProgress(50)

                let addressAr = [];
                let ids = [];
                for (let i = 0; i < nftData[0]; i++) {
                    addressAr[i] = account;
                    ids[i] = i;
                }

                const testData = await MulticallContractWeb3([
                    nftContract.methods.balanceOfBatch(addressAr, ids)
                ]);

                let eth_price = (data[1][1] / data[1][0]) * Math.pow(10, 12);
                let carbon_price = eth_price / (data[0][0] / data[0][1]);

                setStats({
                    eth_price: eth_price,
                    carbon_price: carbon_price,
                    token_balance: data[3] / Math.pow(10, data[2]),
                    user_nft: testData[0],
                    nft_balance: testData[0].length > 0 ? testData[0].reduce((total, val) => parseInt(total) + parseInt(val)) : 0,
                    holdingWorth: parseFloat(data[3] / Math.pow(10, data[2])) * parseFloat(carbon_price),
                    isAdmin: parseInt(testData[0][0]) > 0 ? true : false,
                    votingWeights: nftData[1]
                })

                setProgress(100)
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason);
                setProgress(100)

            }
        }

        if (account) {
            fetch();
        }
        else {
            setProgress(100)
            setStats({
                eth_price: 0,
                carbon_price: 0,
                token_balance: 0,
                user_nft: [],
                nft_balance: 0,
                holdingWorth: 0,
                isAllowedMembers: false,
                isAdmin: false,
                votingWeights: 0

            })
        }
        // eslint-disable-next-line
    }, [updater, account, chainId]);

    return stats;
}


export const useAccountNftStats = (updater) => {
    const { account, chainId } = useWeb3React();

    const [stats, setStats] = useState({
        nft_list: [],
        getPower: [],
        loading : true
    });


    const daoContract = getDaoContract();

    useEffect(() => {
        const fetch = async () => {

            try {

                const nftData = await MulticallContractWeb3(
                    [
                        daoContract.methods.getPower(), //0
                    ]
                );

                let nftList = [];

                getWalletNFTDetails(account).then((res) => {
                    console.log(res.userNftData)
                    if (res.error === 'OK' && res.userNftData) {
                        nftList = res.userNftData;
                    }
                }).then(() => {
                    setStats({
                        nft_list: nftList,
                        getPower: nftData[0],
                        loading : false
                    })
                })

            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason);


            }
        }

        if (account) {
            fetch();
        }
        else {
            setStats({
                nft_list: [],
                getPower: [],
                loading : true
            })
        }
        // eslint-disable-next-line
    }, [updater, account, chainId]);

    return stats;
}
