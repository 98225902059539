import { useContext, useEffect, useState } from "react"
import { contract, DEFAULT_CHAIN } from "./constant";
import { MulticallContractWeb3 } from "../hooks/useContracts";
import { toast } from "react-toastify";
import { getDaoContract, getLpContract, getTokenContract } from "./contractHelper";
import { Context } from "../context/context";

export const useTokenInfoStats = (updater) => {

  let { setProgress } = useContext(Context);

  const [stats, setStats] = useState({
    decimal: 0,
    totalSuppl: 0,
    getCirculatingSupply: 0,
    eth_price: 0,
    carbon_price: 0,
    liquidity: 0,
    totalBuy: 0,
    totalSell: 0,
    votingWeights: [],
  });

  const multiCallContract = MulticallContractWeb3();
  const tokenContract = getTokenContract();
  const carbonLpContract = getLpContract(contract[DEFAULT_CHAIN].CARBON_LP_ADDRESS);
  const ethLpContract = getLpContract(contract[DEFAULT_CHAIN].WETH_USD_LP_ADDRESS);
  const daoContract = getDaoContract();



  useEffect(() => {
    const fetch = async () => {
      setProgress(40);
      try {

        const data = await MulticallContractWeb3([
          tokenContract.methods.totalBuy(), //0
          tokenContract.methods.totalSell(), //1
          tokenContract.methods.decimals(), //2
          tokenContract.methods.totalSupply(), //3
          tokenContract.methods.getCirculatingSupply(), //4
          carbonLpContract.methods.getReserves(), //5
          ethLpContract.methods.getReserves(), //6
          tokenContract.methods.balanceOf(contract[DEFAULT_CHAIN].CARBON_LP_ADDRESS), //7
          daoContract.methods.getPower() //8
        ])


        let eth_price = (data[6][1] / data[6][0]) * Math.pow(10, 12);
        let carbon_price = eth_price / (data[5][0] / data[5][1])


        setStats({
          decimal: data[2],
          totalSuppl: data[3] / Math.pow(10, data[2]),
          getCirculatingSupply: data[4] / Math.pow(10, data[2]),
          eth_price: eth_price,
          carbon_price: carbon_price,
          liquidity: parseFloat(data[7] / Math.pow(10, data[2])) * parseFloat(carbon_price) * 2,
          totalBuy: data[0],
          totalSell: data[1],
          votingWeights: data[8]
        })
        setProgress(100);

      }
      catch (err) {
        console.log(err.message);
        toast.error(err.reason);
        setProgress(100);


      }

    }

    if (multiCallContract) {
      fetch();
    }
    else {
      setStats({
        decimal: 0,
        totalSuppl: 0,
        getCirculatingSupply: 0,
        eth_price: 0,
        carbon_price: 0,
        liquidity: 0,
        totalBuy: 0,
        totalSell: 0,
        votingWeights: []
      })
    }
    // eslint-disable-next-line
  }, [updater]);

  return stats;
}
