import React, { useState } from 'react';
import logoIcon from '../images/logo.png';
import logoText from '../images/icon.png';
import Connect from './Connect';

export default function Header() {
    const [menuToggle, setMenuToggle] = useState(false);


    return (
        <React.Fragment>
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="d-flex justify-content-center navbar-header">
                        <a className="navbar-brand" href="#sec">
                            {menuToggle ? (
                                <b>
                                    {/* <i className="wi wi-sunset"></i> */}
                                    <img src={logoText} alt="homepage" className="dark-logo mobile-logo" />
                                    {/* <img src={logoLightIcon} alt="homepage" className="light-logo" /> */}
                                </b>
                            ) : (
                                <b>
                                    <img src={logoIcon} alt="homepage" height={30} width={30} className="dark-logo logo-img" />
                                    {/* <img src={logoLightText} className="light-logo" alt="homepage" /> */}
                                </b>
                            )
                            }
                        </a>
                    </div>
                    <div className="navbar-collapse">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <a className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="#sec" onClick={()=>{setMenuToggle(!menuToggle)}}>
                                    <i className="ti-menu"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="#sec" onClick={()=>{setMenuToggle(!menuToggle)}}>
                                    <i className="icon-menu text-white"></i>
                                </a>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-lg-0">
                            <li className="nav-item dropdown">
                                <Connect/>

                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

            
        </React.Fragment>
    )
}
