export const supportNetwork = { 
    1 : {
        name : "Ethereum",
         chainId : 1,
         rpc : "https://mainnet.infura.io/v3/c340baf441a74c52ba27da70425a1ae2",
         image : "binance-coin.png",
         symbol : 'ETH'
    } 
}

export const RPC_URLS = {
    // 56: "https://bsc-dataseed1.defibit.io/",
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
     // 5: "https://goerli.infura.io/v3/c340baf441a74c52ba27da70425a1ae2",
    // 4: "https://rinkeby.infura.io/v3/c340baf441a74c52ba27da70425a1ae2",
    // 137 : "https://polygon-rpc.com/",
     1 : "https://mainnet.infura.io/v3/c340baf441a74c52ba27da70425a1ae2"
  };
