import React from 'react';
import { getWeb3 } from '../hooks/connectors';
import { IPFS_URL } from '../hooks/constant';
import { useAccountNftStats } from '../hooks/useAccount';
import shinysilverLogo from '../images/shinysilver-Logo.png';
import { useWeb3React } from '@web3-react/core';


export default function UserNftSection() {
    const { account } = useWeb3React();
    const nftStats = useAccountNftStats(1);
    const web3 = getWeb3();

    return (
        <div className='row'>
            <div className="card-body">
                <h5 className="card-title font-18 font-bold text-dark">Your NFT's </h5>
            </div>
            <div className="col-12">
                <div className='row'>
                    {account ? (
                        nftStats && !nftStats.loading ? (
                            nftStats.nft_list && nftStats.nft_list.length > 0 ? (
                                nftStats.nft_list.map((rowdata, index) => {
                                    let imageIpfs = rowdata.metadata.image ? rowdata.metadata.image.replace('ipfs://', IPFS_URL) : shinysilverLogo;
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="card profile-card">
                                                <img className="card-img profile-bg" height="250" src={imageIpfs} alt="card-img" />
                                                <div className="card-body text-center">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h4 className="m-b-0 nft-text mb-1">{rowdata.balance ? rowdata.balance : 0}</h4>
                                                            <h6 className="font-light nft-text2">Count</h6></div>
                                                        <div className="col">
                                                            <h4 className="m-b-0 nft-text mb-1">{rowdata.id && rowdata.id.tokenId ? web3.utils.hexToNumberString(rowdata.id.tokenId) : 0}</h4>
                                                            <h6 className="font-light nft-text2">Token Id</h6></div>
                                                        <div className="col">
                                                            <h4 className="m-b-0 nft-text mb-1">{rowdata.id && rowdata.balance && rowdata.id.tokenId && nftStats.getPower && nftStats.getPower[web3.utils.hexToNumberString(rowdata.id.tokenId)] ? nftStats.getPower[web3.utils.hexToNumberString(rowdata.id.tokenId)] * rowdata.balance : 0}</h4>
                                                            <h6 className="font-light nft-text2">Power</h6></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="card">
                                    <div className="text-center mt-5 mb-5">
                                        No C12 NFT Found in wallet
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="card">
                                <div className="text-center mt-5 mb-5">
                                    <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="card">
                            <div className="text-center mt-5 mb-5">
                                Please Connect Wallet
                            </div>
                        </div>
                    )
                    }
                </div>

            </div>
        </div>
    )
}
