import moment from "moment";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}


export const formatDate = (unixTime) => {
  try {
    let dateString = moment(unixTime).format('lll');
    return dateString;
  }
  catch (err) {
    console.log(err.message);
  }
}

export const contract = {
  
  1: {
    MULTICALL_ADDRESS: "0x5e227ad1969ea493b43f840cff78d08a6fc17796",
    CARBON_ADDRESS: "0x65526d2b86ff1ac0a3a789fc6ff9c36d35673f1b",
    WETH_ADDRESS: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    CARBON_LP_ADDRESS: "0x8a43fda954b0fa7c9231fb7197b9cb91077de944",
    WETH_USD_LP_ADDRESS: "0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852",
    DAO_ADDRESS: "0x90681F385d59938F730769dD1344F99f9dDf4F6a",
    NFT_ADDRESS: "0x82BeEc866ae3A1a5FC1f8bB7A3DCCD17E223C949"
  }
}

export const DEFAULT_CHAIN = 1;
export const ALCHEMY_API_KEY = '7ALHbqE6c7NLDD9q4chDf0QBoRvq6s22';
export const ALCHEMY_BASE_URL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}/getOwnersForCollection/`;
export const IPFS_URL = 'https://ipfs.moralis.io:2053/ipfs/';
export const ALCHEMY_BASE_URL_WALLET = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}/getNFTs/`;
