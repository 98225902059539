import React from 'react'

export default function PreLoader() {
    return (
        <div className="preloader">
            <div className="loader">
                <div className="loader__figure"></div>
                <p className="loader__label">Carbon12 DAO</p>
            </div>
        </div>
    )
}
