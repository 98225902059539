import React from 'react';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from 'react-router-dom';
import ChangingProgressProvider from "../components/ChangingProgressProvider";
import { formatDate, trimAddress } from '../hooks/constant';
import { formatPrice } from '../hooks/contractHelper';
import { useProposalDashboardStats } from '../hooks/useProposals';
import { useTokenInfoStats } from '../hooks/useTokenInfo';
import shinysilverLogo from '../images/shinysilver-Logo.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





export default function Dashboard() {

  const tokenStats = useTokenInfoStats(1);
  const proposalStats = useProposalDashboardStats();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row g-0 mt-5 ">
          <div className="col-lg-3 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-grid"></i></h3>
                        <p className="text-white">CIRCULATING SUPPLY</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    {tokenStats.getCirculatingSupply ? formatPrice(tokenStats.getCirculatingSupply) : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-briefcase"></i></h3>
                        <p className="text-white">MARKET CAP</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    ${tokenStats.getCirculatingSupply ? formatPrice(tokenStats.getCirculatingSupply * tokenStats.carbon_price) : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-chart"></i></h3>
                        <p className="text-white">CURRENT PRICE(C12)</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    ${tokenStats.carbon_price ? formatPrice(tokenStats.carbon_price) : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="card border info-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div>
                        <h3><i className="icon-wallet"></i></h3>
                        <p className="text-white">LIQUIDITY</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 main-text-below">
                    ${tokenStats.liquidity ? formatPrice(tokenStats.liquidity) : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="card">
              <div className="card-body">
                <iframe src="https://dexscreener.com/ethereum/0x65526d2b86ff1ac0a3a789fc6ff9c36d35673f1b?embed=1&theme=dark&info=0" style={{ "height": "519px" , "width": "100%" }} title="BlockStar Swap"></iframe>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <div className="row">


              <div className="col-md-12">
                <div className="card info-card text-white">
                  <div className="card-body">
                    <h4 className="card-title">Recent Proposal</h4>




                    {proposalStats && !proposalStats.loading ? (
                      proposalStats.proposals && proposalStats.proposals.length > 0 ? (
                        <Slider {...settings}>

                          {proposalStats.proposals.map((rowdata, index) => {
                            return (
                              <Link to={`/proposal-info/${rowdata[0] ? rowdata[0] : 0}`} key={index}>
                                <div class="comment-text w-100" key={index}>
                                  <h5 className="font-medium address-text text-white">
                                    <a href={`https://etherscan.io/address/${rowdata[1] ? rowdata[1] : ' - '}`} target="_blank" rel="noreferrer" >
                                      {rowdata.ensName ? rowdata.ensName.length > 15 ? trimAddress(rowdata.ensName) : rowdata.ensName : ' - '}
                                    </a>
                                  </h5>
                                  <p class="m-b-10 text-white">{rowdata.proposalTitle ? rowdata.proposalTitle.length > 200 ? `${rowdata.proposalTitle.slice(0, 200)}...` : rowdata.proposalTitle : ' - '}</p>
                                  <div class="comment-footer">
                                    <span class="text-muted pull-right">{rowdata[3] ? formatDate(rowdata[3] * 1000) : ' - '}</span>



                                  </div>
                                  <div className="d-flex no-block mt-4">
                                    <span><img src={shinysilverLogo} alt="user" width={30} height={30} className="img-circle" /></span>
                                    <span className="m-l-10">
                                      <p className="m-b-0 address-text">{rowdata.ensName ? rowdata.ensName.length > 20 ? trimAddress(rowdata.ensName) : rowdata.ensName : ' - '}</p>
                                      <p className="text-white">Author</p>
                                    </span>
                                    <span className="m-l-40 text-end">
                                      {rowdata[4] === '0' ? (
                                        <span className="badge rounded-pill bg-success mx-1">
                                          Active
                                        </span>
                                      ) : (

                                        rowdata[4] === '1' ? (
                                          <span className="badge rounded-pill bg-danger mx-1">
                                            Closed
                                          </span>
                                        ) : (
                                          rowdata[4] === '2' ? (
                                            <span className="badge rounded-pill bg-danger mx-1">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="badge rounded-pill bg-info mx-1">
                                              Pending
                                            </span>
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            )
                          })}
                        </Slider>
                      ) : (
                        <div className="text-center mt-5 mb-5">
                          No Record Found
                        </div>
                      )
                    ) : (
                      <div className="text-center mt-5 mb-5">
                        <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                      </div>
                    )
                    }

                  </div>

                </div>
              </div>

              <div className="col-md-12">
                <div className="card info-card">
                  <div className="card-body">
                    <h5 className="card-title text-white text-center">Token Tax</h5>
                    <div className="row justify-content-between">
                      <div className="col-5 buy-tax">

                        <div className="text-end">
                          <ChangingProgressProvider values={[0, `${tokenStats.totalBuy ? tokenStats.totalBuy / 10 : 0}`]}>
                            {value => (
                              <CircularProgressbar
                                value={value}
                                text={`${value}%`}
                                circleRatio={0.75}


                                styles={buildStyles({
                                  rotation: 1 / 2 + 1 / 8,
                                  strokeLinecap: "butt",
                                  trailColor: "#eee",
                                  pathColor: '#00c292'
                                })}
                              />
                            )}
                          </ChangingProgressProvider>
                          <h6 className="card-title text-success text-center">Buy Tax</h6>

                        </div>

                      </div>
                      <div className="col-5 sell-tax">
                        <ChangingProgressProvider values={[0, `${tokenStats.totalSell ? tokenStats.totalSell / 10 : 0}`]}>
                          {value => (
                            <CircularProgressbar
                              value={value}
                              text={`${value}%`}
                              circleRatio={0.75}

                              styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                strokeLinecap: "butt",
                                trailColor: "#eee",
                                pathColor: '#e46a76'
                              })}
                            />
                          )}
                        </ChangingProgressProvider>
                        <h6 className="card-title text-danger text-center">Sell Tax</h6>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
