import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import {  getAllNFTDetails, getHolderVotingPower } from "./contractHelper";


export const useVotersStats = (updater) => {
    const [stats, setStats] = useState({
        voterList: [],
        loading: true
    });



    useEffect(() => {
        const fetch = async () => {
            try {
                getAllNFTDetails().then((response) => {
                    if (response.error === 'OK') {
                        if (response.data && response.data.length > 0) {
                            getHolderVotingPower(response.data).then(async (result) => {
                                if (result.error === 'OK') {
                                    setStats({
                                        voterList: result.userVotingPower,
                                        loading: false
                                    })
                                }
                            })
                        }
                    }
                })

            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)

            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}
