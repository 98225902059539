import { createContext, useState } from 'react';



export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const [progress, setProgress] = useState(20);
    

   
   
    return (
        <Context.Provider
            value={{
                progress,
                setProgress
            }}
        >{children}</Context.Provider>
    )
}

