import React, { useState, useEffect } from 'react';
// import ProgressLine from '../components/ProgressLine';
import { useProposalIdStats } from '../hooks/useProposals';
import { Link, useHistory } from 'react-router-dom'
import { useRouter } from '../components/Sidebar';
import { contract, formatDate, DEFAULT_CHAIN, trimAddress } from '../hooks/constant';
import shinysilverLogo from '../images/shinysilver-Logo.png';
import { getWeb3 } from '../hooks/connectors';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import daoAbi from '../json/daoAbi.json';
import { formatPrice, getContract } from '../hooks/contractHelper';
import VotersInfo from '../components/VotersInfo';



export default function ProposalInfo() {
    const { account, chainId, library } = useWeb3React()
    const [updater, setUpdater] = useState('');
    const [id, setId] = useState('');
    const proposalStats = useProposalIdStats(id ,updater);
   
    const [proposalId, setProposalId] = useState(0);
    const router = useRouter();
    let history = useHistory();



    useEffect(() => {
        let id = router.pathname.split('/').length > 2 ? router.pathname.split('/')[2] : '';
        if (id === '') {
            history.push('/')
        }
        else {
            setId(id);
            setProposalId(id);
        }

        // eslint-disable-next-line
    }, [])


    const handleCastVote = async (index) => {
       
        try {
            if (account) {
                if (chainId) {
                    if (index !== '' && parseInt(index) >= 0) {
                        let daoContract = getContract(daoAbi, contract[DEFAULT_CHAIN].DAO_ADDRESS, library);
                        let tx = await daoContract.castVote(proposalId, index, { 'from': account });
                        toast.loading('Waiting for confirmation..');
                        var interval = setInterval(async function () {
                            let web3 = getWeb3();
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('success ! your last transaction is success');
                                    
                                    setUpdater(1);
                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('error ! Your last transaction is failed.');
                                    
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('error ! something went wrong.');
                                    
                                    setUpdater(1);
                                }
                            }
                        }, 5000);
                    }
                    else {
                        toast.dismiss();
                        toast.error('something went wrong!!');
                        setUpdater(1);
                    }
                }
                else {
                    toast.dismiss();
                    toast.error('Please connect to mainnet chain!!');
                    
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect wallet!!');
                
            }
        }
        catch (err) {
            toast.dismiss();
            toast.error(err.reason ? err.reason : err.message);
        }
    }


    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                {proposalStats && !proposalStats.loading ? (
                    <>
                        <div className="row mt-4">
                            <div className='col-12'>
                                <div className='d-flex justify-content-between'>
                                    <Link to="/dao-details" className="btn btn-warning m-3 text-white">
                                        <i className="icon-arrow-left-circle my-1"></i><span className="btn-text mx-1">Back</span>
                                    </Link>
                                </div>
                            </div>

                            <React.Fragment>
                                <div className="col-md-12 col-lg-8">
                                    <div className="card ">
                                        <div className="comment-widgets ps ps--theme_default" >
                                            <div className="d-flex no-block comment-row border-top">
                                                <div className='col-2 col-md-1'>
                                                    <div className="p-2">
                                                        <span className="round">
                                                            <img src={shinysilverLogo} alt="user" className='dao-logo' />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-10 col-md-11'>
                                                    <div className="comment-text comment-text-overflow w-100">
                                                        <div className='d-flex justify-content-between'>
                                                            <h5 className="font-medium h5-a-text address-text">{proposalStats.author ? proposalStats.author : 0}</h5>
                                                            <div>
                                                                {proposalStats.status === '0' ? (
                                                                    <span className="badge rounded-pill bg-success mx-1">
                                                                        Active
                                                                    </span>
                                                                ) : (

                                                                    proposalStats.status === '1' ? (
                                                                        <span className="badge rounded-pill bg-danger mx-1">
                                                                            Closed
                                                                        </span>
                                                                    ) : (
                                                                        proposalStats.status === '2' ? (
                                                                            <span className="badge rounded-pill bg-danger mx-1">
                                                                                Cancelled
                                                                            </span>
                                                                        ) : (
                                                                            <span className="badge rounded-pill bg-info mx-1">
                                                                                Pending
                                                                            </span>
                                                                        )
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p className="m-b-10 text-muted" style={{ "whiteSpace": "pre-wrap" }}>
                                                            {proposalStats.proposalText ? proposalStats.proposalText : ' - '}
                                                        </p>
                                                        <div className="comment-footer">
                                                            <span className="text-muted pull-right">{proposalStats.createAt ? formatDate(proposalStats.createAt * 1000) : '-'}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card vote-card mb-5 pb-3">

                                                <h4 className="card-title h5-a-text vote-title">Cast your vote &nbsp;&nbsp;
                                                    <span className='text-danger font-10'>{`${proposalStats.getProposalUserVote ? '(* Already voted)' : ''}`}</span></h4>

                                                {
                                                    proposalStats.totalOption.map((val, index) => {
                                                        let totalVote = proposalStats.getProposalVotes && proposalStats.getProposalVotes[index] ? proposalStats.getProposalVotes[index] : 0;
                                                        let totalPer = (totalVote / proposalStats.totalPower) * 100;

                                                        return (
                                                            <div className='vote-btn'>
                                                                <div className="answer selected" disabled={proposalStats.getProposalUserVote ? proposalStats.getProposalUserVote : false} onClick={() => handleCastVote(index)}>
                                                                    <span className="percentage_bar" style={{ "width": `${totalPer <= 100 ? totalPer : 100}%` }}></span>
                                                                    <span className='percentage_value1'>{val}</span>
                                                                    <span className="percentage_value">{totalPer <= 100 ? formatPrice(totalPer) : 100}%</span>
                                                                </div>
                                                                {/* <button type="button" className="button px-[22px] relative mb-2 block mt-3" key={index} disabled={proposalStats.getProposalUserVote ? proposalStats.getProposalUserVote : false} onClick={() => handleCastVote(index)}>
                                                                    {loading.id === index && loading.status ? 'loading...' : val}
                                                                </button> */}
                                                            </div>
                                                        )

                                                    })

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="row">
                                        <div className='col-12'>
                                            <div className="card">
                                                <div className="card-body info-card-span">
                                                    <h4 className="card-title border-bottom">Information</h4>
                                                    <div className='d-flex no-block justify-content-between mt-2'>
                                                        <span>Proposal Id</span>
                                                        <span>{proposalStats.id ? proposalStats.id : ' - '}</span>
                                                    </div>
                                                    <div className='d-flex no-block justify-content-between mt-2'>
                                                        <span>Author</span>
                                                        <span>{proposalStats.author ? trimAddress(proposalStats.author) : '-'}</span>
                                                    </div>
                                                    <div className='d-flex no-block justify-content-between mt-2'>
                                                        <span>Start date</span>
                                                        <span>{proposalStats.createAt ? formatDate(proposalStats.createAt * 1000) : ''}</span>
                                                    </div>
                                                    <div className='d-flex no-block justify-content-between mt-2'>
                                                        <span>Status</span>
                                                        <span>
                                                            {proposalStats.status === '0' ? (
                                                                <span className="badge rounded-pill bg-success mx-1">
                                                                    Active
                                                                </span>
                                                            ) : (

                                                                proposalStats.status === '2' ? (
                                                                    <span className="badge rounded-pill bg-danger mx-1">
                                                                        Closed
                                                                    </span>
                                                                ) : (
                                                                    proposalStats.status === '1' ? (
                                                                        <span className="badge rounded-pill bg-danger mx-1">
                                                                            Cancelled
                                                                        </span>
                                                                    ) : (
                                                                        <span className="badge rounded-pill bg-info mx-1">
                                                                            Pending
                                                                        </span>
                                                                    )
                                                                )
                                                            )}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                        <VotersInfo />
                    </>
                ) : (
                    <div className='row mt-4'>
                        <div className='col-12 col-md-12'>
                            <div className='card'>
                                <div className="comment-widgets ps ps--theme_default">
                                    <div className="text-center mb-5 mt-5">
                                        <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </div>
    )
}
