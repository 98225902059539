import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../hooks/constant';
import { useProposalStats } from '../hooks/useProposals';
// import ProgressLine from '../components/ProgressLine';
import shinysilverLogo from '../images/shinysilver-Logo.png';


export default function DaoDetails() {
    const pageSize = 4;
    const [updater, setUpater] = useState({ page: 1, pageSize: pageSize, oldData: [], select: '1' });
    const proposalStats = useProposalStats(updater);

    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="col-lg-12 mt-4">
                    <div className='col-12'>
                        <div className='d-flex justify-content-end'>
                            <select className="b-0 select-menu" onChange={(e)=> setUpater({...updater,select : e.target.value})}>
                                <option value="1" selected={updater.select === "1" ? true : false}>All</option>
                                <option value="2" selected={updater.select === "2" ? true : false}>Active</option>
                                <option value="3" selected={updater.select === "3" ? true : false}>Closed</option>
                                <option value="4" selected={updater.select === "4" ? true : false}>Cancelled</option>
                            </select>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Proposals</h5>
                        </div>

                        <div className="comment-widgets ps ps--theme_default">
                            {proposalStats && !proposalStats.loading ? (
                                proposalStats.proposals && proposalStats.proposals.length > 0 ? (
                                    <>
                                        {proposalStats.proposals.map((rowdata, index) => {
                                            return (
                                                <>
                                                    {((updater.select === '1') || (updater.select === '2' && rowdata[4] === '0') || (updater.select === '3' && rowdata[4] === '1') || (updater.select === '4' && rowdata[4] === '2')) &&
                                                        <div className="row no-block comment-row border-top" key={index}>
                                                            <div className='col-2 col-md-1'>
                                                                <div className="p-2">
                                                                    <span className="round">
                                                                        <img src={shinysilverLogo} alt="user" className='dao-logo' />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-10 col-md-11'>
                                                                <div className="comment-text w-100">
                                                                    <div className='d-flex justify-content-between'>
                                                                        <h5 className="font-medium address-text">
                                                                            <a href={`https://etherscan.io/address/${rowdata[1] ? rowdata[1] : ' - '}`} target="_blank" rel="noreferrer" >
                                                                                {rowdata.ensName ? rowdata.ensName : ' - '}
                                                                            </a>
                                                                        </h5>
                                                                        <div>
                                                                            {rowdata[4] === '0' ? (
                                                                                <span className="badge rounded-pill bg-success mx-1">
                                                                                    Active
                                                                                </span>
                                                                            ) : (

                                                                                rowdata[4] === '1' ? (
                                                                                    <span className="badge rounded-pill bg-danger mx-1">
                                                                                        Closed
                                                                                    </span>
                                                                                ) : (
                                                                                    rowdata[4] === '2' ? (
                                                                                        <span className="badge rounded-pill bg-danger mx-1">
                                                                                            Cancelled
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="badge rounded-pill bg-info mx-1">
                                                                                            Pending
                                                                                        </span>
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <Link to={`/proposal-info/${rowdata[0] ? rowdata[0] : 0}`}>
                                                                        <p className="m-b-10 text-dark address-text" style={{ "whiteSpace": "pre-wrap" }}>{rowdata.proposalTitle ? rowdata.proposalTitle : ' - '}</p>
                                                                        <div className="comment-footer">
                                                                            <span className="text-dark pull-right">{rowdata[3] ? formatDate(rowdata[3] * 1000) : ' - '}</span>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>


                                            )


                                        })}
                                        {Math.ceil(proposalStats.lastId / updater.pageSize) > parseInt(updater.page) &&
                                            <div className='text-center'>
                                                {proposalStats.loading ? (
                                                    <button className="btn btn-primary text-white" type="button" disabled="">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                ) : (
                                                    <button type="button" onClick={() => setUpater({ ...updater ,page: updater.page + 1, oldData: proposalStats.proposals })} className="btn waves-effect waves-light btn-rounded btn-secondary">
                                                        Load More
                                                    </button>
                                                )}

                                            </div>
                                        }
                                    </>

                                ) : (

                                    <div className="text-center mt-5 mb-5">
                                        <span>No Record Found</span>
                                    </div>

                                )

                            ) : (


                                <div className="text-center mt-5 mb-5">
                                    <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                </div>

                            )}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
