import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { create } from 'ipfs-http-client';
import { useWeb3React } from '@web3-react/core';
import { getContract } from '../hooks/contractHelper';
import daoAbi from '../json/daoAbi.json';
import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import { getWeb3 } from '../hooks/connectors';


const projectId = '2Kdnmw3iNgznnwhpHu25OfBJWoT';
const projectSecret = '6f2c61db00f56194b68dd66a63da00f9';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
const client = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  apiPath: '/api/v0',
  headers: {
    authorization: auth,
  }
})

export default function Authorized() {
  const { account, chainId, library } = useWeb3React()
  const [pinfo, setPinfo] = useState('');
  const [ipfsHash, setIpfsHash] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState([{
    service: 'Yes',
  }]);


  const addInputField = () => {

    setInputFields([...inputFields, {
      service: '',
    }])

  }

  const removeInputFields = (index, e) => {
    e.preventDefault();
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (account) {
        if (chainId) {
          const rows = [...inputFields];
          let voteOption = [];
          rows.map((product) => {
            if (product.service !== '') {
              voteOption.push(product.service);
            }
            return true;
          })

          if (pinfo !== '' && voteOption.length > 0) {
            console.log(voteOption);
            const fileData = JSON.stringify(pinfo);
            const added = await client.add(fileData);

            if (added && added.path && added.path !== '') {
              setIpfsHash(added.path);
              let daoContract = getContract(daoAbi, contract[DEFAULT_CHAIN].DAO_ADDRESS, library);
              let tx = await daoContract.createProposal(added.path, voteOption, { 'from': account });
              toast.loading('Waiting for confirmation..');

              var interval = setInterval(async function () {
                let web3 = getWeb3();
                var response = await web3.eth.getTransactionReceipt(tx.hash);
                if (response != null) {
                  clearInterval(interval)
                  if (response.status === true) {
                    toast.dismiss();
                    toast.success('success ! your last transaction is success');
                    setLoading(false);
                  }
                  else if (response.status === false) {
                    toast.dismiss();
                    toast.error('error ! Your last transaction is failed.');
                    setLoading(false);
                  }
                  else {
                    toast.dismiss();
                    toast.error('error ! something went wrong.');
                    setLoading(false);
                  }
                }
              }, 5000);

            }
            else {
              toast.error('hash not found on upload ! Please try again');
            }
          }
          else {
            toast.dismiss();
            toast.error('Please enter valid details!!');
            setLoading(false);
          }
        }
        else {
          toast.dismiss();
          toast.error('Please connect to mainnet chain!!');
          setLoading(false);
        }
      }
      else {
        toast.dismiss();
        toast.error('Please connect wallet!!');
        setLoading(false);
      }
    }
    catch (err) {
      toast.dismiss();
      toast.error(err.reason ? err.reason : err.message);
      setLoading(false);
    }
  }

  return (
    <div className="page-wrapper mt-4">
      <div className='container-fluid'>
        <div className="row">
          <div className="col-12">
            {account ? (
              <div className="card">
                <div className="card-body">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Create Proposal</h4>
                        <form className="form-horizontal mt-4">
                          <div className="form-group">
                            <label className="form-label">Proposal Information</label>
                            <textarea className="form-control" onChange={(e) => {
                              setPinfo(e.target.value);
                            }} rows="5">{pinfo}</textarea>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Voting Option</label>
                            <div className='col-sm-6'>

                              {
                                inputFields.map((data, index) => {
                                  const { service } = data;
                                  return (
                                    <div className="input-group">

                                      <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={service} name="service" className="form-control mb-1" placeholder="Enter option value.." />
                                      {(index === 0) ? (
                                        <div className="input-group-append text-end">
                                          <button className="btn btn-success text-white mx-1" type="button" onClick={(e) => addInputField(e)}>
                                            <i className="fa fa-plus"></i>
                                          </button>
                                        </div>

                                      ) : (
                                        <div className="input-group-append">
                                          <button className="btn btn-success text-white mx-1" type="button" onClick={(e) => removeInputFields(index, e)}>
                                            <i className="fa fa-minus"></i>
                                          </button>
                                        </div>
                                      )}

                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                          {ipfsHash &&
                            <div className="form-group">
                              <label className="form-label">Genrated IPFS</label>
                              <input type="text" value={ipfsHash} readOnly={true} className="form-control" />
                            </div>
                          }


                          <div className="form-actions">
                            <div className="card-body text-center">

                              <button type="button" disabled={loading} className="btn btn-success text-white" onClick={(e) => handleSubmit(e)}>
                                {loading ? 'loading...' : 'Create'}
                              </button>


                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12 mt-5 mb-5">
                    <div className="text-center">
                      <h1 className="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
                      <hr className="w3-border-white w3-animate-left" style={{ "margin": "auto", "width": "50%" }} />
                      <h3 className="w3-center w3-animate-right">You dont have permission to view this page.</h3>
                      <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
                      <h6 className="w3-center w3-animate-zoom">error code:403 forbidden</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
